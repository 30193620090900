
@import "assets/css/app";

.version {
  font-size: 0.75rem;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  & > div {
    position: fixed;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}
@media print {
  #app nav {
    display: none;
  }
  body {
    zoom: 0.75;
  }
}
