
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
.content {
  width: 650px;
  margin: auto;
}
#summary {
  table-layout: fixed;
}
#summary th {
  text-align: center;
  border: solid 1px #333;
  background-color: #ccc;
}
#summary td {
  text-align: right;
  padding: 0.5rem;
  border: solid 1px #333;
}
