
.row > * {
  margin-bottom: 1rem;
}
h3,
h4 {
  text-align: center;
}
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
.btn-outline-success {
  background-color: #eee;
  color: #999;
  border: 1px solid #999;
}
.btn-outline-success:hover:not:focus {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-outline-success:active {
  background-color: #1a8754;
  color: #000;
  border: 1px solid #000;
}
.modal {
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
