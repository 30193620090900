
.row > * {
  margin-bottom: 1rem;
}
textarea:not(.is-invalid) {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
}
textarea.is-invalid {
  width: 100%;
  border: 1px solid #dc3545ff;
  border-radius: 6px;
}
textarea:focus:not(.is-invalid) {
  border: 1px solid rgba(39, 80, 140, 0.81);
  outline: 5px solid rgba(56, 150, 245, 0.3);
}
textarea:focus.is-invalid {
  border: 1px solid rgb(220, 53, 69);
  outline: 5px solid rgba(220, 53, 69, 0.3);
}
textarea:disabled {
  background-color: #e9ecef;
}
