@import "variables";
@import "~bootstrap/scss/bootstrap";

* {
    font-family: "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;
    color: #333;
}

nav.navbar {
  .navbar-brand img {
    height: 3rem;
  }
}
.alert .btn-close {
  width: auto;
  height: auto;
}
.is-invalid .select2 .select2-selection {
  border-color: $red;
}
.select2 {
  width: 100% !important;
}
.select2-container .select2-selection--single {
  height: 37px !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 35px !important;
}

main {
  padding: 2rem 0;
}
h1 {
  position: relative;
  margin-top: 0;
  padding: 10px 10px 10px 40px;
  border: 1px solid #777;
  border-bottom: 2px solid #555;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 23px;
  font-weight: 700;
  color: #444;
  line-height: 35px;
  text-shadow: 2px 2px 2px rgb(200 200 200 / 90%);

  &:after {
    content: '';
    position: absolute;
    top: 14px;
    left: 7px;
    width: 25px;
    height: 25px;
    border: 5px solid #3498DB;
    border-radius: 100%;
  }
}

h2 {
  margin-top: 0.5em;
  padding: 5px 5px 5px 10px;
  border-bottom: 1px solid #AAA;
  border-left: 15px solid #AAA;
  font-size: 20px;
  color: #444;
}

table.table {
  th {
    text-align: center;
    color: #FFF;
    padding: 8px 15px;
    background: linear-gradient(rgba(34, 85, 136, 0.7), rgba(34, 85, 136, 0.9));
    font-weight: bold;
    border-left: 1px solid #258;
    border-top: 1px solid #258;
    border-bottom: 1px solid #258;
    line-height: 120%;
    text-shadow: 0 -1px 0 rgb(34 85 136 / 90%);
    box-shadow: 0 1px 1px rgb(255 255 255 / 30%) inset;
    vertical-align: middle;
  }

  td {
    vertical-align: middle;
  }
}