
table {
  th {
    position: sticky;
    top: 0;
    padding: 0.25rem;
  }

  tr:hover {
    background-color: #d1d2d4;
  }

  td {
    padding: 0.25rem;
  }
}
