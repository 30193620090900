
.list-group {
  max-height: 100px;
  overflow: auto;
}
.list-group .badge {
  width: 100px;
}

.justify-content-center > .btn,
.justify-content-center > a {
  margin: 0 1.5rem;
}
.btn {
  &.btn-start,
  &.btn-end {
    line-height: 1;
    width: 214px;
    height: 124px;
    font-size: 32px;
    color: white;

    &:disabled {
      background-color: #aaa;
    }
  }

  &.btn-start {
    background-color: #56b9cc;
  }

  &.btn-end {
    background-color: #e3598d;
  }

  span {
    color: white;
  }
}
a.menu-item {
  text-align: center;
  text-decoration-color: #333;
  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  &:hover img {
    transition: 0.3s;
    opacity: 0.8;
    transform: scale(1.05, 1.05);
  }
}
